<div class="rounded-lg bg-white !p-5 min-w-[584px]">
    <h3 class="text-palette-gray-900 text-lg font-semibold">
        {{ 'UPDATE_BOOKING_ISSUE.FLIGHT_ITINERARY' | translate }}
    </h3>
    <p class="text-palette-gray-600 text-sm">
        {{ 'UPDATE_BOOKING_ISSUE.FLIGHT_ITINERARY_CONTENT' | translate }}
    </p>
    <div class="!my-4 flex flex-col !gap-3 text-palette-gray-900 text-base font-medium custom-checkbox"
        [ngClass]="{'invalid': isValidateItinerary && !isChosenDepart && !isChosenReturn}">
        <p-checkbox 
            [(ngModel)]="isChosenDepart"
            [label]="depart" 
            name="itinerary"
            [binary]="true" />
        <p-checkbox 
            [(ngModel)]="isChosenReturn"
            [label]="return" 
            name="itinerary" 
            [binary]="true" />
        <div *ngIf="isValidateItinerary && !isChosenDepart && !isChosenReturn"
            class="text-palette-red-600 text-xs flex items-center !gap-1">
            <i class="sctr-icon-alert-circle text-palette-red-600"></i>
            {{ 'UPDATE_BOOKING_ISSUE.FLIGHT_ITINERARY_WARNING' | translate }}
        </div>
    </div>
    <div class="flex justify-end items-center !gap-3">
        <button (click)="onClickCancel()"
            class="rounded-lg border border-palette-gray-300 bg-white hover:!bg-palette-gray-200 text-palette-gray-900 text-sm font-semibold !px-5 !py-3 transition-all">
            {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button (click)="onClickContinue()"
            class="rounded-lg bg-palette-blue-600 hover:!bg-palette-blue-500 text-white text-sm font-semibold !px-5 !py-3 transition-all">
            {{ 'COMMON.CONTINUE' | translate }}
        </button>
    </div>
</div>