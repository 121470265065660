<div
    class="my-price-details-section w-full min-[1100px]:w-[384px] !p-4 bg-palette-base-white border border-palette-gray-200 rounded-lg">
    <p class="text-palette-gray-900 font-inter text-base font-semibold !mb-3">
        {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.TITLE' | translate}}</p>
    <div class="flex flex-col !gap-3">
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.BOOKING_STATUS' | translate}}
            </span>
            <span [ngClass]="handleClassBookingStatus(flightDetail?.booking_info.booking_status)"
                class="py-[2px] px-2 font-inter text-xs font-medium leading-4 rounded-full">
                {{flightDetail?.booking_info.booking_status}}
            </span>
        </div>
        <!-- <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.REFERENCE_ID' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5">{{flightDetail?.booking_info.reference_id}}</span>
        </div> -->
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.BOOKING_ID' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5">{{flightDetail?.booking_info.reservation_code}}</span>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.PIN_CODE' | translate}}
            </span>
            <div class="flex items-center !gap-3">
                <span class="text-palette-gray-900 font-inter text-sm font-medium leading-5">
                    {{isShowPinCode ? flightDetail?.booking_info.pin_code :
                    hidePinCode(flightDetail?.booking_info.pin_code)}}
                </span>
                <i (click)="togglePinCode()"
                    class="{{isShowPinCode ? 'sctr-icon-eye' : 'sctr-icon-eye-off'}} text-xl text-palette-blue-600 cursor-pointer">
                </i>
            </div>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.BOOKING_DATE' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5">{{flightDetail?.booking_info.booking_date | dateFormat : true}}</span>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.PAYMENT_METHOD' | translate}}
            </span>
            <div>
                <span class="text-palette-gray-700 font-inter text-sm font-medium leading-5 mr-1">
                    {{flightDetail?.booking_info.payment_method}}
                </span>
                <span *ngIf="flightDetail?.booking_info.is_paid"
                    class="py-[2px] px-1 bg-palette-emerald-500 rounded text-palette-base-white font-inter text-xs font-medium leading-4">
                    {{'FLIGHT.PAYMENT.PAID' | translate}}
                </span>
            </div>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'BOOKING_HELPDESK.SOURCE' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5 uppercase">{{ flightDetail?.booking_info.resource }}</span>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.B2B_WEBSITE' | translate}}
            </span>
            <a
                class="font-inter text-sm font-normal leading-5 underline text-palette-blue-600 text-right"
                [href]="flightDetail?.b2b_website_url"
            >
                {{ flightDetail?.b2b_website_url }}
            </a>
        </div>
    </div>
</div>