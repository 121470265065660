import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, SimpleChanges } from '@angular/core';

interface ExpandState {
    isExpand: boolean;
    isMouseOver: boolean;
}

@Component({
    selector: 'seat-selection',
    templateUrl: './seat-selection.component.html',
    styleUrls: ['./seat-selection.component.scss'],
    animations: [
        trigger('slideDownUp', [
            state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
            state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
            transition(':enter', [animate('300ms ease-out')]),
            transition(':leave', [animate('300ms ease-in')]),
        ]),
        trigger('slideInOut', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition(':enter', [animate('300ms ease-in')]),
            transition(':leave', [animate('300ms ease-out')]),
        ]),
    ],
})
export class SeatSelectionComponent {
    @Input() currentModeView: string;
    @Input() segments: any;
    @Input() passengerInfo: any

    isInitiated: boolean = false;
    expandSeat: ExpandState = { isExpand: false, isMouseOver: false };
    dataConvert: any = [];

    constructor() { }

    ngOnInit() {
        this.initData();
        this.isInitiated = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currentModeView'] && changes['currentModeView'].currentValue && this.isInitiated) {
            this.initData();
        }
    }

    initData() {
        const dataConvert: any = {};

        // Get all seat selected by passenger
        this.passengerInfo.forEach((passengerInfo: any) => {
            const { title, last_name, middle_and_first_name, departure_seat, return_seat } = passengerInfo;
            const seats = this.currentModeView === 'DEPART' ? departure_seat : return_seat;

            seats?.forEach((seat: any) => {
                const key = `${seat?.origin_iata_code}_${seat?.destination_iata_code}`;

                if (!dataConvert[key]) {
                    dataConvert[key] = [];
                }
                dataConvert[key].push({
                    title,
                    last_name,
                    middle_and_first_name,
                    ...seat
                });
            });
        });

        // Sort by itineraries flight
        if (this.segments.length && !this.isEmptyObject(dataConvert)) {
            let index = 0;
            while (index < this.segments.length) {
                let depart = this.segments[index]?.departure?.iata_code;
                let arrival = this.segments[index]?.arrival?.iata_code;

                Object.entries(dataConvert).forEach(([key, value]) => {
                    const [origin_iata_code, destination_iata_code] = key.split('_');
                    if (depart == origin_iata_code) {
                        this.dataConvert.push(value);
                        while (arrival != destination_iata_code && index < this.segments.length) {
                            index++;
                            arrival = this.segments[index]?.arrival?.iata_code;
                        }
                        delete dataConvert[key];
                        index++;
                        return;
                    }
                });
            }
        }
    }

    isEmptyObject(obj: object): boolean {
        return Object.keys(obj).length === 0;
    };

    handleClickExpandCollapse(state: ExpandState) {
        state.isExpand = !state.isExpand;
    }

    handleMouseOverLeave(state: ExpandState, isOver: boolean) {
        state.isMouseOver = isOver;
    }
}
