import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, SimpleChanges } from '@angular/core';

interface ExpandState {
    isExpand: boolean;
    isMouseOver: boolean;
}
@Component({
    selector: 'meal-selection',
    templateUrl: './meal-selection.component.html',
    styleUrls: ['./meal-selection.component.scss'],
    animations: [
        trigger('slideDownUp', [
            state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
            state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
            transition(':enter', [animate('300ms ease-out')]),
            transition(':leave', [animate('300ms ease-in')]),
        ]),
        trigger('slideInOut', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition(':enter', [animate('300ms ease-in')]),
            transition(':leave', [animate('300ms ease-out')]),
        ]),
    ],
})
export class MealSelectionComponent {
    @Input() currentModeView: string;
    @Input() segments: any;
    @Input() passengerInfo: any

    isInitiated: boolean = false;
    expandMeal: ExpandState = { isExpand: false, isMouseOver: false };
    dataConvert: any = [];

    ngOnInit() {
        this.initData();
        this.isInitiated = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currentModeView'] && changes['currentModeView'].currentValue && this.isInitiated) {
            this.initData();
        }
    }

    initData() {
        const dataConvert: any = {};
        const totalElements: any = [];
        // Get all selected meals by segments
        this.passengerInfo.forEach((passengerInfo: any) => {
            const { departure_meals, return_meals } = passengerInfo;
            const meals = this.currentModeView === 'DEPART' ? departure_meals : return_meals;

            meals?.forEach((mealsSegment: any) => {
                const key = `${mealsSegment?.origin_iata_code}_${mealsSegment?.destination_iata_code}`;

                if (!dataConvert[key]) {
                    dataConvert[key] = [];
                }

                let total_element = totalElements[key] ?? 0;
                mealsSegment.meals.forEach((meal: any) => {
                    total_element += meal.quantity;
                    const existingMeal = dataConvert[key]?.find(
                        (m: any) => m.meal_name === meal.meal_name && m.price === meal.price
                    );
                    if (existingMeal) {
                        existingMeal.quantity += meal.quantity;
                    } else {
                        dataConvert[key].push({ ...meal });
                    }
                });
                totalElements[key] = total_element;
            });
        });

         // Sort by itineraries flight
         if (this.segments.length && !this.isEmptyObject(dataConvert)) {
            let index = 0;
            while (index < this.segments.length) {
                let depart = this.segments[index]?.departure?.iata_code;
                let arrival = this.segments[index]?.arrival?.iata_code;

                Object.entries(dataConvert).forEach(([key, value]) => {
                    const [origin_iata_code, destination_iata_code] = key.split('_');
                    if (depart == origin_iata_code) {
                        this.dataConvert.push({
                            origin_iata_code,
                            destination_iata_code,
                            total_elements: totalElements[key],
                            data: value
                        });
                        while (arrival != destination_iata_code && index < this.segments.length) {
                            index++;
                            arrival = this.segments[index]?.arrival?.iata_code;
                        }
                        delete dataConvert[key];
                        index++;
                        return;
                    }
                });
            }
        }
    }

    isEmptyObject(obj: object): boolean {
        return Object.keys(obj).length === 0;
    };

    handleClickExpandCollapse(state: ExpandState) {
        state.isExpand = !state.isExpand;
    }

    handleMouseOverLeave(state: ExpandState, isOver: boolean) {
        state.isMouseOver = isOver;
    }
}
