<div class="!mt-2 border-1 border-palette-gray-200 rounded-lg bg-white !p-4 font-inter">
    <div (click)="handleClickExpandCollapse(expandMeal)" (mouseover)="handleMouseOverLeave(expandMeal, true)"
        (mouseleave)="handleMouseOverLeave(expandMeal, false)"
        class="flex items-center justify-between cursor-pointer">
        <div class="flex items-center !gap-4">
            <img class="!h-[56px] aspect-square" src="assets/images/flight-payment/meal.svg" alt="">
            <p [ngClass]="{'!font-extrabold':expandMeal.isMouseOver}"
                class="text-lg font-semibold text-palette-gray-900">
                {{'FLIGHT.PAYMENT.MEAL_SELECTION' | translate}}</p>
        </div>
        <i [ngClass]="{'sctr-icon-chevron-down':expandMeal.isExpand, 'sctr-icon-chevron-right':!expandMeal.isExpand, 'font-extrabold':expandMeal.isMouseOver}"
            class="text-xl leading-5 text-palette-gray-500"></i>
    </div>
    <div @slideDownUp *ngIf="expandMeal.isExpand"
        class="{{dataConvert.length > 1 ? '!grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4' : ''}} !mt-4">
        <ng-container *ngIf="dataConvert.length; else mealNotIncluded">
            <ng-container *ngFor="let mealsSegment of dataConvert">
                <div class="!p-4 border border-palette-gray-200 bg-palette-gray-50 rounded-md">
                    <div class="flex items-center !gap-2 text-base !font-medium text-palette-gray-900">
                        <span>{{ mealsSegment?.origin_iata_code }}</span>
                        <i class="sctr-icon-arrow-narrow-right text-[20px]"></i>
                        <span>{{ mealsSegment?.destination_iata_code }}</span>
                    </div>
                    <p class="text-palette-gray-600 text-sm !mt-2 !mb-1">
                        {{ mealsSegment.total_elements }} 
                        {{'COMMON.MEAL' | translate: { syntax:mealsSegment.total_elements != 1 ? 's': '' } }}
                    </p>
                    <div class="flex flex-col !gap-0.5">
                        <p *ngFor="let meal of mealsSegment?.data"
                            class="text-sm !font-medium text-palette-gray-900">
                            {{ meal?.meal_name }} x{{ meal?.quantity }}
                        </p>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #mealNotIncluded>
            <div class="text-palette-gray-700 text-sm font-semibold">Not Included</div>
        </ng-template>
    </div>
</div>