import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-popup-flight-itinerary',
    templateUrl: './popup-flight-itinerary.component.html',
    styleUrls: ['./popup-flight-itinerary.component.scss'],
})
export class PopupFlightItineraryComponent{
    @Input() depart: string;
    @Input() return: string;
    @Output() clickCancel = new EventEmitter<any>();
    @Output() clickContinue = new EventEmitter<any>();

    isValidateItinerary: boolean = false;
    isChosenDepart: boolean = false;
    isChosenReturn: boolean = false;

    onClickCancel() {
        this.clickCancel.emit();
    }

    onClickContinue() {
        if (!this.isChosenDepart && !this.isChosenReturn) {
            this.isValidateItinerary = true;
        } else {
            this.clickContinue.emit({
                isChosenDepart: this.isChosenDepart,
                isChosenReturn: this.isChosenReturn,
            });
        }
    }
}
