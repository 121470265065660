import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { SOCTRIP_HOTLINE } from '@shared/constant';

@Component({
  selector: 'flight-schedule',
  templateUrl: './flight-schedule.component.html',
  styleUrls: ['./flight-schedule.component.scss']
})
export class FlightScheduleComponent {
  @Input() bookingInfo: any;
  @Input() segments: any;
  @Input() segment_type: any;
  @Input() flightInfo: any;

  SOCTRIP_HOTLINE = SOCTRIP_HOTLINE;
  baseImage = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

  constructor(private translateService: TranslateService) {}

  ngOnInit() { }

  formatTime(timeString: string): string {
    const timeParts = timeString.split(':');
    const date = new Date();
    date.setHours(Number(timeParts[0]));
    date.setMinutes(Number(timeParts[1]));

    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }

  formatDuration(timeString: string, space: boolean): string {
    const timeParts = timeString.split(':');
    const hours = Number(timeParts[0]);
    const minutes = Number(timeParts[1]);

    let formattedDuration = '';

    if (hours > 0) {
      if (space) {
        formattedDuration += `${hours}h `;
      } else {
        formattedDuration += `${hours}h`;
      }
    }

    formattedDuration += `${minutes}m`;

    return formattedDuration;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/user-management/default-image.jpg';
  }

  formatBaggages(baggage: any): string {    
    if(baggage?.number && baggage?.weight) {
      return `${baggage?.number}x${baggage?.weight} ${baggage?.unit}`;
    } else if(baggage?.number && baggage?.weight == null) {
      return `${baggage?.number} ${this.translateService.instant('COMMON.PCS')}`;
    } else if(baggage?.number == null && baggage?.weight) {
      return `${baggage?.weight} ${baggage?.unit}`;
    } else if(baggage?.description == 'included') {
      return this.translateService.instant('FLIGHT.VIEW_DETAILS_SIDEBAR.INCLUDED');
    } else
      return '';
  }
}
