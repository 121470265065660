<div class="bg-white rounded-lg border border-palette-gray-200 shadow-sm !p-4">
    <p class="text-palette-gray-900 text-base font-semibold">{{ 'FLIGHT.MY_BOOKING.FLIGHT_SCHEDULE' | translate }}</p>
    <div class="!mt-4 !flex items-center !gap-3">
        <div
            class="!px-2 !py-[2px] font-inter text-xs font-medium bg-palette-blue-50 text-branding-primary-600 rounded flex items-center justify-center">
            <ng-container *ngIf="segment_type === 'departure'; else returnTemplate">
                {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.DEPART' | translate }}
            </ng-container>
            <ng-template #returnTemplate>
                {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.RETURN' | translate }}
            </ng-template>
        </div>
        <div class="border-l-[2px] border-l-palette-gray-200 flex items-center text-sm font-normal text-palette-gray-700">
            <p class="!pl-3">{{segments[0].travel_class}}</p>
        </div>
    </div>
    <div class="!mt-3 relative rounded-lg bg-white !flex flex-col min-[1460px]:flex-row !gap-10">
        <div class="flex-2/3">
            <ng-container *ngFor="let item of segments; let first = first; let last = last">
                <div class="!flex flex-row !gap-6">
                    <div class="!flex flex-col !gap-4 justify-between">
                        <div class="text-right">
                            <p class="text-sm font-medium text-palette-gray-700">
                                {{formatTime(item.time_departure_at)}}</p>
                            <p class="text-xs text-palette-gray-500 font-normal">{{ item.date_departure_at | dateFormat }}</p>
                        </div>
                        <div
                            class="flex flex-row-reverse text-right w-[80px] text-sm font-normal text-palette-gray-700">
                            {{formatDuration(item.duration, true)}}
                        </div>
                        <div class="text-right">
                            <p class="text-sm font-medium text-palette-gray-700">
                                {{formatTime(item.time_arrival_at)}}</p>
                            <p class="text-xs text-palette-gray-500 font-normal">{{ item.date_arrival_at | dateFormat }}</p>
                        </div>
                    </div>
                    <div class="!flex flex-col items-center">
                        <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                        <div class="h-full border border-dashed w-[1px]"></div>
                        <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                    </div>
                    <div class="!flex flex-col !gap-4">
                        <div>
                            <p class="text-sm font-medium text-palette-gray-700">{{item.departure.city_name}}
                                ({{item.departure.iata_code}})</p>
                            <p class="text-xs text-palette-gray-500 font-normal">
                                {{item.departure.airport_name}} {{item.departure.terminal}}
                            </p>
                        </div>
                        <div class="flex flex-col !gap-2">
                            <div class="flex flex-row items-center !gap-2">
                                <!-- <img class="" src="{{item.operating_logo_url}}" alt=""> -->
                                <img 
                                    *ngIf="item.operating_logo_id"
                                    loading="lazy"
                                    class="rounded-md max-w-[70px] max-h-[30px]"
                                    src="{{ baseImage }}/{{ item.operating_logo_id }}.webp"
                                    (error)="replaceWithDefaultImage($event)" alt="">
                                <img 
                                    *ngIf="!item.operating_logo_id"
                                    loading="lazy"
                                    class="rounded-md max-w-[70px] max-h-[30px]"
                                    [src]="item.operating_logo_url"
                                    (error)="replaceWithDefaultImage($event)"
                                    alt=""
                                >
                                <div class="text-sm font-normal text-palette-gray-700">
                                    {{item.operating_name}} {{item.operating_code}}{{item.operating_number}}
                                </div>
                            </div>
                            <div *ngIf="item.baggage_allowance">
                                <div class="flex flex-col !gap-2.5">
                                    <div class="flex flex-row items-center !gap-2">
                                        <i class="sctr-icon-luggage-02 text-xl text-palette-gray-500"></i>
                                        <span class="font-inter text-sm font-normal text-palette-gray-900">
                                            {{ 'FLIGHT.SEARCH_RESULT.CABIN_BAGGAGE' | translate }} 
                                            {{ formatBaggages(item.baggage_allowance?.cabin_baggage) }}
                                        </span>
                                    </div>
                                    <div class="flex flex-row items-center !gap-2">
                                        <i class="sctr-icon-luggage-01 text-xl text-palette-gray-500"></i>
                                        <span class="font-inter text-sm font-normal text-palette-gray-900">
                                            {{ 'FLIGHT.SEARCH_RESULT.CHECKED_BAGGAGE' | translate }} 
                                            <ng-container *ngIf="formatBaggages(item.baggage_allowance?.checked_baggage) == ''; else showBaggage">
                                                <span class="font-inter text-sm font-normal text-palette-gray-900 lowercase">
                                                    {{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.NOT_INCLUDED'| translate}}
                                                </span>
                                            </ng-container>
                                            <ng-template #showBaggage>
                                                <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                    {{ formatBaggages(item.baggage_allowance?.checked_baggage) }}
                                                </span>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p class="text-sm font-medium text-palette-gray-700">{{item.arrival.city_name}}
                                ({{item.arrival.iata_code}})</p>
                            <p class="text-xs text-palette-gray-500 font-normal">
                                {{item.arrival.airport_name}} {{item.arrival.terminal}}
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="!last" class="!px-3 !py-1 bg-palette-blue-50 rounded font-inter flex flex-col !gap-1 !my-3">
                    <div class="text-xs font-medium text-palette-blue-light-600">
                        {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.TRANSFER_IN' | translate: { syntax: item.city_name_transfer, syntax2: formatDuration(item.waiting_transfer, false) } }}
                    </div>
                    <div *ngIf="item.is_difference_terminal"
                        class="text-xs !text-[10px] font-normal text-palette-orange-dark-600">
                        {{'FLIGHT.VIEW_DETAILS_SIDEBAR.DIFFERENT_TERMINAL' | translate}}
                    </div>
                    <div *ngIf="!item.is_difference_terminal"
                        class="text-xs !text-[10px] font-normal text-palette-orange-dark-600">
                        {{'FLIGHT.VIEW_DETAILS_SIDEBAR.VISA_REQUIRED' | translate}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="hidden min-[1460px]:!block w-[1px] border border-dashed"></div>
        <div class="block min-[1460px]:!hidden h-[1px] border border-dashed"></div>
        <div class="flex-1/3 flex flex-col items-center min-[1460px]:items-start justify-center">
            <h3 class="text-sm text-palette-gray-600"> {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.PNR' | translate }}</h3>
            <p class="!mt-4 text-xl font-bold text-palette-gray-700 first-letter:uppercase">
                <ng-container *ngIf="segment_type === 'departure'">
                    {{ bookingInfo?.reference_id || '---' }}
                </ng-container>
                <ng-container *ngIf="segment_type === 'arrival'">
                    {{ bookingInfo?.inbound_reference_id || '---' }}
                </ng-container>
            </p>
            <p class="mt-[2px] text-[10px] leading-5 text-palette-gray-600 italic"> {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.PNR_USED' | translate }}</p>
            <p class="!mt-4 text-sm text-palette-gray-400">{{'FLIGHT.VIEW_DETAILS_SIDEBAR.GDS_SOURCE' | translate}}</p>
            <p class="!mt-4 text-xl font-bold text-palette-gray-700">{{ flightInfo.source || '---' }}</p>
        </div>
    </div>
    <div class="!mt-3">
        <div class="flex flex-row !gap-2 !p-3 border border-palette-blue-600 rounded-xl bg-palette-blue-50">
            <i class="sctr-icon-info-circle text-palette-blue-600 text-xl"></i>
            <p class="text-palette-gray-700 font-normal text-sm">
                <span>{{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.DESCRIPTION' | translate}}</span>
                <span class="font-semibold">{{SOCTRIP_HOTLINE}}</span>
                <span>{{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.DESCRIPTION_END' | translate}}</span>
            </p>
        </div>
    </div>
</div>